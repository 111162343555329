import React, { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TreeViewComponent from "../component/TreeViewComponent.js";

import Paper from "@material-ui/core/Paper";
import { getLocations, addLocation } from "../../utils/LocationApi";
import { AuthContext } from "../../contexts/AuthContext";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },

  areabar: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },

  areamain: {
    fontSize: 24,
    color: "white",
  },

  button: {
    margin: "10px",
    marginLeft: "auto",
  },

  tablecell: {
    fontSize: "16px",
  },
}));

function AreaSettings({ OnAreaSelected }) {
  const { t } = useContext(GlobalContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [areaName, setAreaName] = React.useState("");
  const [treeData, setTreeData] = React.useState([]);
  // const { token } = useContext(AuthContext); // 取得Token
  const [refreshData, setRefreshData] = React.useState(false);
  const [enable, setEnable] = React.useState(true);

  const RefreshLocation = useCallback(() => {
    // console.log(ApiHost);
    getLocations()
      .then((response) => {
        const treeData = response.data.data;
        setTreeData(treeData);
      })
      .catch((error) => {
        console.error("query fail", error);
      });
  }, []);

  useEffect(() => {
    // 在組件加載的時候得到資料
    RefreshLocation();
  }, [RefreshLocation]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveArea = () => {
    addLocation({
      name: areaName,
      isEnabled: true,
      parentLocationId: null,
    })
      .then((response) => {
        //重新query 資料
        RefreshLocation();
      })
      .catch((error) => {
        console.error("query fail", error);
      });
    setAreaName(""); // 清空輸入框
    handleClose();
  };

  const handleTreeItemClick = (area) => {
    if (OnAreaSelected) OnAreaSelected(area);
  };

  const handleChange = () => {
    setEnable(!enable);
  };

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <Grid item xs={6}>
          <h2 style={{ margin: "20px" }}>區域列表</h2>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddBoxIcon />}
            onClick={handleClickOpen}
            style={{ margin: "10px" }}
          >
            Add
          </Button>
        </Grid>
      </Grid>

      <Paper variant="outlined">
        <Grid container spacing={3}>
          <Grid item xs={12} style={{ margin: "20px" }}>
            {treeData &&
              treeData.map(
                (
                  data,
                  index // 檢查 treeData 是否存在
                ) => (
                  <div key={index} style={{ marginBottom: "10px" }}>
                    <TreeViewComponent
                      key={data.locationId}
                      data={data}
                      style={{ height: "300px", width: "300px" }}
                      refreshData={refreshData}
                      setRefreshData={RefreshLocation}
                      showButton={true}
                      onTreeItemClick={handleTreeItemClick}
                      expand={true}
                    />
                  </div>
                )
              )}
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">新增區域</DialogTitle>
        <DialogContent>
          區域名稱:
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="名稱"
            onChange={(e) => setAreaName(e.target.value)}
            fullWidth
          />
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={enable}
                  onChange={handleChange}
                  name="isEnabled"
                  color="primary"
                />
              }
              label="啟用"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveArea} color="primary">
            確定
          </Button>
          <Button variant="contained" onClick={handleClose} color="secondary">
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AreaSettings;
