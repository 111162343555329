import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/parkinglot";

export const getAllParkingLots = (skip = 0, amount = 10) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}`
  );
};

export const getParkingLots = (locationId, skip = 0, amount = 10) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}?param[locationid]=${locationId}&skip=${skip}&amount=${amount}`
  );
};

export const getParkingLotById = (parkingLotId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${parkingLotId}`);
};

export const addParkingLot = (parkingLotData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, parkingLotData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateParkingLot = (parkingLotData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(
        `${ROOT_URL}${BASE_URL}/${parkingLotData.parkingLotId}`,
        parkingLotData
      )
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteParkingLot = (parkingLotId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${parkingLotId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};
