import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/user/role";



export const getRoles = (skip=0, amount=10, withRelation = true) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}&withRelation=${withRelation}`
  );
};

export const getRoleById = (roleId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${roleId}`);
};

export const addRole = (roleData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, roleData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const updateRole = (roleDataId,updateData) => {
  console.log(updateData);
  return new Promise((resolve, reject) => {
    apiProvider
      .put(
        `${ROOT_URL}${BASE_URL}/${roleDataId}`,
        updateData
      )
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteRole= (roleId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${roleId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};


export const getRoleAttributes = (roleId) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}/attribute?param[roleId]=${roleId}`
  );
};

export const getRoleAttributeById = (attrId) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}/attribute/${attrId}`
  );
};

export const addRoleAttribute = (attrData) => {
  console.log(attrData);

  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}/attribute`, attrData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
}; 

export const updateRoleAttribute = (attrId,updateData) => {

  return new Promise((resolve, reject) => {
    apiProvider
      .put(
        `${ROOT_URL}${BASE_URL}/attribute/${attrId}`,
        updateData
      )
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};


export const deleteRoleAttribute= (attrId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/attribute/${attrId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};