import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext.js";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import defaulttheme from "../../customTheme.js";


import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";


import {
  getAllParkingSpaces,
  GetParkingSpaceMappingWithPile
} from "../../utils/ParkingSpaceApi.js";
import {
  getParkingTickets,
  getParkingTicketEvents,
  getParkingTicketHistoriesById,
  getParkingTicketSnapshotList,
} from "../../utils/ParkingTicketApi.js";
import { Button, DialogContent, DialogContentText } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getAllParkingLots} from "../../utils/ParkingLotApi.js";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LaunchIcon from '@material-ui/icons/Launch';

import { ROOT_URL } from "../../config/api.js";
import Pagination from '@material-ui/lab/Pagination';
import ReactImageMagnify from 'react-image-magnify';
import { signalRConnection } from '../../utils/signalrService.js'
import Typography from "@material-ui/core/Typography";
import TicketSnapshotDialog from "../component/TicketSnapshotDialog.js";
import TicketsSnapshotDialog from "../component/TicketsSnapshotDialog.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { getParkingMeters } from "../../utils/ParkingMeterApi.js";
import { getParkingMeterMapping } from "../../utils/ParkingPileApi.js";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),

  },
  button: {
    marginLeft: "5px",
    marginRight: "5px",
    marginTop: "5px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginBottom: theme.spacing(5),
    height: "90%",
  },
  imageList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    width: "100%",
    height: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  imageList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },

}));

const theme = createTheme({
  typography: {
    fontSize: 16, // 設置文字大小
  },


});

const images = ["./testimage/1.jpg", "./testimage/2.jpg", "./testimage/3.jpg", "./testimage/4.jpg"]
const successImage = "./success-icon.png";

const DuplicateTicket = () => {
  const classes = useStyles();
  const { t, showSnackbar } = useContext(GlobalContext);
  // const { token } = useContext(AuthContext); // 取得Token
  const [parkingSpaces, setParkingSpaces] = React.useState([]);
  const [selectedParkingSpaceId, setSelectedParkingSpace] = React.useState("");
  const [selectedTicketData, setSelectedTicketData] = React.useState("");
  const [openDlg, setOpenDlg] = React.useState(false);
  const [parkingLots, setParkingLots] = React.useState([]);
  const [imageItems, setImageItems] = React.useState([]);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [page, setPage] = React.useState(1); // 初始頁碼為12
  const [total, setTotal] = React.useState(0);
  const rowsPerPage = 10;
  const [parkingTicketEventData, setParkingTicketEventData] = React.useState([]);
  const [currentEventIndex, setCurrentEventIndex] = React.useState(0);
  const [newPlateNumber, setPlateNumber] = React.useState("");
  const [successAnimationVisible, setSuccessAnimationVisible] = React.useState(false);

  const [openTicketSnapshotDialog, setOpenTicketSnapshotDialog] = React.useState(false);

  const [openRelatedTicketDialog, setOpenRelatedTicketDialog] = React.useState(false);
  const [relatedTickets, setRelatedTickets] = React.useState([]);

  useEffect(() => {
    // 在組件加載的時候得到資料

    // GetEventData();
    GetParkingTicketEvent();

  }, []);


  // const GetEventData = async () => {
  //   setOpenBackdrop(true);
  //   const parkSpaces = await RefreshParkingSpace();
  //   const parkLots = await RefreshParkingLots();
  //   const ticketEvent = await GetParkingTicketEventData();

  //   const eventDatas = ticketEvent ? (await Promise.all(
  //     ticketEvent.map(async (ticket) => {
  //       const space = await GetParkingSpace(
  //         parkSpaces,
  //         ticket.parkingTicket.parkingSpaceId
  //       );
  //       const Lot = space
  //         ? await GetParkingLot(parkLots, space.parkingLotId)
  //         : null;

  //       const imageList = await Promise.all(
  //         ticket.parkingTicketSnapshots.map(async (imageInfo, index) => {
  //           return {
  //             img: `${ROOT_URL}/api/v1/parkingTicket/${ticket.parkingTicket.parkingTicketId}/snapshot/${imageInfo.filename}`,
  //             reason: imageInfo.reason,
  //             plate: imageInfo.plate,
  //           };
  //         })
  //       );
  //       return {
  //         ticketEvent: ticket,
  //         parkingSpace: space,
  //         parkingLot: Lot,
  //         images: imageList
  //       };
  //     })
  //   )) : null;

  //   setParkingTicketEventData(eventDatas);
  //   console.log(eventDatas);
  //   setOpenBackdrop(false);
  // }

  const GetParkingTicketEventData = async () => {

    try {
      const resp = await signalRConnection.invoke('GetUnhandledEvent');
      // console.log(resp);
      setTotal(resp.length);
      return resp;
    } catch (err) {
      console.error("query fail", err);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
      return null;
    }
  }

  const RefreshParkingSpace = async () => {
    try {
      let allSpaces = [];
      let limit = 10;

      var response = await getAllParkingSpaces(0, 1);
      var resp = response.data;
      allSpaces = resp.data;

      if (resp.total > resp.amount) {
        response = await getAllParkingSpaces(0, resp.total);
        resp = response.data;
        allSpaces = resp.data;
      }

      setParkingSpaces(allSpaces);
      return allSpaces;
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

  const RefreshParkingLots = async () => {
    try {
      let allParkingLots = [];
      let limit = 10;
      var response = await getAllParkingLots(0, 1);
      var resp = response.data;
      allParkingLots = resp.data;

      if (resp.total > resp.amount) {
        response = await getAllParkingLots(0, resp.total);
        resp = response.data;
        allParkingLots = resp.data;
      }

      setParkingLots(allParkingLots);
      return allParkingLots;
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };
  
  const RefreshParkingMeters = async () => {
    try {
      let allParkingMeters = [];
      let limit = 10;
      var response = await getParkingMeters(0, 1);
      var resp = response.data;
      allParkingMeters = resp.data;

      if (resp.total > resp.amount) {
        response = await getParkingMeters(0, resp.total);
        resp = response.data;
        allParkingMeters = resp.data;
      }
      return allParkingMeters;
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };

 



  const handleClick = async () => {
    GetParkingTicketEvent();
  };

  const GetParkingTicketEvent = async (newPage = 1) => {
    setOpenBackdrop(true);
    try {
      const parkSpaces = await RefreshParkingSpace();
      const parkLots = await RefreshParkingLots();
      const response = await getParkingTicketEvents(2, rowsPerPage * (newPage - 1), rowsPerPage);
      const reports = response.data.data;
      setTotal(response.data.total);

      //console.log(reports);

      let ticketWithSpaceAndLot = [];

      for (var idx in reports) {
        var report = reports[idx];
        const space = await GetParkingSpace(
          parkSpaces,
          report.parkingTicket.parkingSpaceId
        );
        const Lot = space
          ? await GetParkingLot(parkLots, space.parkingLotId)
          : null;
        const meter = space?
        await GetParkingMeter(space)
        : null;
             

        ticketWithSpaceAndLot.push({
          ticket: report,
          parkingSpace: space,
          parkingLot: Lot,
          parkingMeter: meter
        });
      };

      setParkingTicketEventData(ticketWithSpaceAndLot);
       //console.log(ticketWithSpaceAndLot);
      setOpenBackdrop(false);
    } catch (error) {
      setParkingTicketEventData([]);
      setOpenBackdrop(false);
    }
  };

  const GetParkingSpace = async (packSpaces, parkingSpaceId) => {
    try {
      const parkSpace = packSpaces.find(
        (space) => space.parkingSpaceId === parkingSpaceId
      );

      return parkSpace || null;
    } catch (error) {
      return null;
    }
  };

  const GetParkingLot = async (parkLots, parkingLotId) => {
    try {
      const parkLot = parkLots.find((lot) => lot.parkingLotId === parkingLotId);
      return parkLot || null;
    } catch (error) {
      return null;
    }
  };

  const GetParkingMeter = async (parkingSpace) => {
    try {
      let mappings = [];
      let limit = 10;
      var response = await GetParkingSpaceMappingWithPile(parkingSpace.parkingSpaceId);
      var resp = response.data;
      mappings = resp.data;
      
      if(mappings.length <= 0)
        return null;
      
      let parkingPileId = mappings[0].parkPileId;
      var response2 = await getParkingMeterMapping(parkingPileId);
      var resp2 = response2.data;
      let parkingMeters = resp2.data;
      if(parkingMeters != null && parkingMeters.length > 0)
        return parkingMeters[0];
      else
        return null;
    
    
    } catch (error) {
      console.error("query fail", error);
      showSnackbar({
        message: "取得資料失敗",
        severity: "error",
      });
    }
  };



  const ISO8601toLocalTime = (iso8601Str) => {
    const iso8601Date = new Date(iso8601Str);

    function formatLocalDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const formattedLocalDateTime = formatLocalDateTime(iso8601Date);
    return formattedLocalDateTime;
  };


  const handleChangePage = (newPage) => {
    setPage(newPage);
    GetParkingTicketEvent(newPage);
  };

  const handleOpenSnapshot = (eventData) => {
    if (eventData && eventData.ticket && eventData.ticket.parkingTicketSnapshots) {

      const images = eventData.ticket.parkingTicketSnapshots.map((image, index) => {
        return {
          img: `${ROOT_URL}/api/v1/parkingTicket/${eventData.ticket.parkingTicket.parkingTicketId}/snapshot/${image.filename}`,
          author: "author",
          title: index,
          time: image.time,
          reason: image.reason,
        };
      });

      setImageItems(images);
      setOpenTicketSnapshotDialog(true);
    }
    else {
      showSnackbar({ message: "沒有快照資料" })
    }
  };

  const handleOpenRelatedTicket = (eventData) => {
    if (eventData && eventData.ticket && eventData.ticket.parkingTicketEvent) {
      setRelatedTickets(eventData.ticket.parkingTicketEvent.content.split(","));
      setOpenRelatedTicketDialog(true);
    }
  };

  const isTicketHasCheckPoint = (eventData) => {

    if (eventData && eventData.ticket && eventData.ticket.parkingTicketHistories) {
      const checkPoint = eventData.ticket.parkingTicketHistories.find(
        (history) => history.content.toLowerCase() === "firstreport" || history.content.toLowerCase() === "checkpoint"
      );
      return checkPoint ? true : false;
    }
    return false;
  };

  // const RenderSelect = (
  //   <div>
  //     <Grid container space={3}>
  //       <Grid item xs={9}>
  //         <label htmlFor="grouped-select">選擇停車路段</label>
  //         <Select
  //           defaultValue=""
  //           d="grouped-select"
  //           onChange={(e) => setSelectedParkingSpace(e.target.value)}
  //           style={{ width: "200px", marginLeft: "10px" }}
  //         >
  //           {parkingLots
  //             ? parkingLots.map((lot, index) => (
  //               <MenuItem value={lot.parkingLotId}>
  //                 {lot.name}
  //               </MenuItem>
  //             ))
  //             : null}
  //         </Select>
  //         <Button
  //           variant="contained"
  //           startIcon={<SearchIcon />}
  //           className={classes.button}
  //           style={{
  //             backgroundColor: defaulttheme.buttonColor.blue,
  //             color: "white",
  //             marginLeft: "20px"
  //           }}
  //           onClick={handleClick}


  //         >
  //           Get
  //         </Button>
  //       </Grid>
  //       <Grid item xs={3} container justifyContent="flex-end">

  //       </Grid>

  //     </Grid>

  //   </div>
  // );

  return (
    <ThemeProvider theme={theme}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* {RenderSelect} */}

      <div style={{ marginTop: "20px", height: '50px', display: 'flex', alignItems: 'center' }}>
        <label style={{ fontSize: "24px", margin: "20px", fontWeight: "bold" }}>重複開單資料</label>
      </div>

      <TableContainer component={Paper} style={{ margin: "0px, 10px,10px, 10px" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow
              style={{ backgroundColor: defaulttheme.blue.delta }}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                height: "10px",
              }}
            >
              {rowHeader.map((header) => (
                <TableCell
                  key={header}
                  align="center"
                  style={{ color: "white", fontSize: 16, fontWeight: "bold" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              parkingTicketEventData ? parkingTicketEventData.map((eventData, index) => {
                return (
                  <TableRow
                    key={eventData.ticketEvent ? eventData.ticketEvent.parkingTicketEvent.parkingTicketEventId : index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    style={{
                      backgroundColor: index % 2 === 0 ? "white" : "#d8f1fd",
                    }}
                  >
                    <TableCell component="th" scope="row" align="center">
                      {eventData.parkingLot ? eventData.parkingLot.name : "N/A"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {eventData.parkingSpace ? eventData.parkingSpace.name : "N/A"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {eventData.parkingMeter ? eventData.parkingMeter.manufacturerDeviceId : "N/A"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {eventData.ticket && eventData.ticket.parkingTicketEvent ? ISO8601toLocalTime(eventData.ticket.parkingTicketEvent.eventAt) : "N/A"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {eventData.ticket && eventData.ticket.parkingTicket ? eventData.ticket.parkingTicket.plateNumber : "N/A"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {isTicketHasCheckPoint(eventData) ? <CheckCircleIcon style={{ color: "green" }} /> : null}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Button
                        variant="contained"
                        startIcon={<LaunchIcon />}
                        className={classes.button}
                        style={{
                          backgroundColor: defaulttheme.buttonColor.blue,
                          color: "white",
                        }}
                        onClick={() => handleOpenSnapshot(eventData)}
                      >
                        {t("openspanshot")}
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Button
                        variant="contained"
                        startIcon={<LaunchIcon />}
                        className={classes.button}
                        style={{
                          backgroundColor: defaulttheme.buttonColor.blue,
                          color: "white",
                        }}
                        onClick={() => handleOpenRelatedTicket(eventData)}
                      >
                        {"查看"}
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              }) : null
            }
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        <Pagination
          count={total > 0 ? Math.ceil(total / rowsPerPage) : 0}
          page={page}
          onChange={(event, newPage) => handleChangePage(newPage)}
        />
      </div>
      {openTicketSnapshotDialog ? (
        <TicketSnapshotDialog open={openTicketSnapshotDialog} setOpen={setOpenTicketSnapshotDialog} imageItems={imageItems} />
      ) : null}
      {openRelatedTicketDialog ? (
        <TicketsSnapshotDialog parkingTicketIds={relatedTickets} open={openRelatedTicketDialog} setOpen={setOpenRelatedTicketDialog} />
      ) : null}
    </ThemeProvider>
  );
};

function GetIsoTime(datetime) {
  const currentLocalDate = datetime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });

  const [month, day, year] = currentLocalDate.split("/");
  const formattedDate = `${year}-${month}-${day}`;

  const currentLocalTime = datetime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // 使用24小时制
  });

  // 将日期和时间组合为ISO格式
  const currentLocalDateTime = `${formattedDate}T${currentLocalTime}`;
  return currentLocalDateTime;
}

const rowHeader = [
  "停車路段",
  "車格名稱",
  "設備編號",
  "事件時間",
  "牌照號碼",
  "是否加簽",
  "停車單快照",
  "關聯停車單"
];

export default DuplicateTicket;
