import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { GlobalContext } from "../contexts/GlobalContext";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Card,
} from "@material-ui/core";

import { keep, tokenIsValid } from "../utils/tokenHandler.js";

import * as LoginApi from "../utils/LoginApi.js";
import { jwtDecode } from "jwt-decode";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {  createSignalRConnection , disconnectSignalRConnection} from '../utils/signalrService.js';

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    backgroundImage: `radial-gradient(circle at 50% 50%, ${theme.blue.delta}, ${theme.blue.dark} 150%)`,
    opacity: 0.88,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    height: 520,
    width: 420,
    // ['@media (max-width: 450px)']: {
    //   height: 470,
    //   width: 340,
    // },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 24,
  },
  title: {
    marginBottom: 20,
    textAlign: "center",
  },
  logo: {
    marginBottom: 20,
    height: 30,
  },
  submit: {
    height: 50,
  },
  spacer: {
    flex: "1 1 auto",
  },
  user: {},
  password: {},
  locale: {},

  backdrop: {
    zIndex: theme.zIndex.drawer + 5,
    color: "#fff",
  },
}));

const Login = () => {
  const classes = useStyles();
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  const { login, setKeepState, keepState } = useContext(AuthContext);
  const { t, changeLocale, locale, showSnackbar } = useContext(GlobalContext);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setOpenBackdrop(true);
    await LoginApi.login(account, password)
      .then((response) => {
        const token = response.data.token;
        console.log(token);

        const jwtToken = token;
        const decodeToken = jwtDecode(jwtToken);
        console.log(decodeToken);

        const nbfUnixTimeStamp = decodeToken.nbf;
        const nbfDate = new Date(nbfUnixTimeStamp * 1000);
        console.log("nbf: " + nbfDate);

        const expUnixTimeStamp = decodeToken.exp;
        const expDate = new Date(expUnixTimeStamp * 1000);
        console.log("exp: " + expDate);

        if (token) {
          login(token, keepState);
          // 登入之後會立刻被導向，又呼叫 setOpenBackdrop 會觸發變更 unmounted component 的錯誤
         
        } else {
          setOpenBackdrop(false);
        }
      })
      .catch((error) => {
        setOpenBackdrop(false);
        showSnackbar({
          message: "登入失敗",
          severity: "error",
        });
      });
  };

  if (tokenIsValid()) {
    {  
      console.log("token exists");
      createSignalRConnection();
      return <Redirect to="/" />;
    }
  }

  const handleChagneLocale = (e) => {
    changeLocale(e.target.value);
  };

  return (
    <div className={classes.container}>
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card className={classes.card}>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <h1 className={classes.title}>{t("welcome")}</h1>
          <FormControl>
            <TextField
              className={classes.user}
              variant="outlined"
              required
              fullWidth
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              label={t("account")}
              style={{ marginBottom: 20 }}
            />
          </FormControl>
          <FormControl>
            <TextField
              className={classes.password}
              variant="outlined"
              required
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              label={t("password")}
              type="password"
              autoComplete="current-password"
            />
          </FormControl>
          <FormControlLabel
            value="end"
            style={{ marginBottom: 10 }}
            control={
              <Checkbox
                color="primary"
                value={keepState}
                onChange={(e) => {
                  setKeepState(e.target.checked);
                }}
              />
            }
            label={t("keep-me")}
            labelPlacement="end"
          />
          <FormControl variant="outlined" fullWidth>
            <Select
              className={classes.locale}
              value={locale}
              onChange={handleChagneLocale}
              displayEmpty
            >
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"zh-TW"}>繁體中文</MenuItem>
            </Select>
          </FormControl>
          <div className={classes.spacer}></div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("login")}
          </Button>
        </form>
      </Card>
    </div>
  );
};

export default Login;
