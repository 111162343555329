import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import FolderIcon from "@material-ui/icons//Folder";
import Button from "@material-ui/core/Button";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import defaulttheme from "../../customTheme.js";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import {
  addLocation,
  deleteLocation,
  updateLocation,
} from "../../utils/LocationApi";
import { AuthContext } from "../../contexts/AuthContext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import UpdateIcon from "@material-ui/icons/Update";
import { GlobalContext } from "../../contexts/GlobalContext";

import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },

  areabar: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    height: "70px",
  },

  areamain: {
    fontSize: 16,
    color: "white",
  },

  button: {
    marginLeft: "5px",
    marginRight: "5px",
  },

  tablecell: {
    fontSize: "12px",
  },

  heading: {
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function TreeViewComponent({
  data,
  refreshData,
  setRefreshData,
  showButton,
  onTreeItemClick,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [open_edit, setOpenEdit] = React.useState(false);
  const [selectNode, setSelectNode] = React.useState(null);
  const [areaName, setAreaName] = React.useState("");
  const [enable, setEnable] = React.useState(true);
  // const { token } = React.useContext(AuthContext); // 取得Token
  const { t } = useContext(GlobalContext);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose_Edit = () => {
    setOpenEdit(false);
  };

  const handleAddClick = (node, event) => {
    event.stopPropagation(); // 阻止事件傳播到父元件
    setSelectNode(node);
    setOpen(true);
  };

  const handleDeleteClick = (nodeId, event) => {
    event.stopPropagation(); // 阻止事件傳播到父元件
    console.log(nodeId);
    deleteLocation(nodeId)
      .then((response) => {
        if (setRefreshData) setRefreshData();
      })
      .catch((error) => {
        console.error("delete fail", error);
      });
  };

  const handleEditClick = (node, event) => {
    event.stopPropagation(); // 阻止事件傳播到父元件
    setSelectNode(node);
    setEnable(node.isEnabled);
    setOpenEdit(true);
  };

  const handleSaveArea = () => {
    addLocation({
      name: areaName,
      isEnabled: enable,
      parentLocationId: selectNode.locationId,
    })
      .then((response) => {
        setOpen(false);
        if (setRefreshData) setRefreshData();
        console.log("Data refreshed in TreeViewComponent");
      })
      .catch((error) => {
        console.error("add fail", error);
      });
  };

  const handleEditArea = () => {
    updateLocation({
      locationId: selectNode.locationId,
      name: areaName,
      isEnabled: enable,
      parentLocationId: selectNode.parentLocationId,
    })
      .then((response) => {
        setOpenEdit(false);
        if (setRefreshData) setRefreshData();
      })
      .catch((error) => {
        console.error("update fail", error);
      });
  };

  const handleTreeItemClick = (node) => {
    if (onTreeItemClick) {
      onTreeItemClick(node);
    }
    setSelectNode(node);
    setAreaName(node.name);
  };

  const handleEditEnable = (isEnabled) => {
    setEnable(isEnabled);
  };

  const renderTree = (nodes, depth, expand) => {
    const fontSize = `${18 - depth}px`;

    const renderButtons = showButton ? (
      <Grid item xs={6} container justifyContent="flex-end">
        <Button
          variant="contained"
          startIcon={<AddBoxIcon />}
          className={classes.button}
          style={{
            backgroundColor: defaulttheme.buttonColor.green,
            color: "white",
          }}
          onClick={(event) => {
            handleAddClick(nodes, event);
          }}
        >
          Add
        </Button>

        <Button
          variant="contained"
          startIcon={<UpdateIcon />}
          className={classes.button}
          style={{
            backgroundColor: defaulttheme.buttonColor.grey,
            color: "white",
          }}
          onClick={(event) => {
            handleEditClick(nodes, event);
          }}
        >
          Edit
        </Button>

        <Button
          variant="contained"
          startIcon={<DeleteIcon />}
          className={classes.button}
          style={{
            backgroundColor: defaulttheme.buttonColor.red,
            color: "white",
          }}
          onClick={(event) => {
            handleDeleteClick(nodes.locationId, event);
          }}
        >
          delete
        </Button>
      </Grid>
    ) : null;

    return (
      <>
        <TreeItem
          key={nodes.locationId}
          nodeId={String(nodes.locationId)}
          label={
            <div onClick={() => handleTreeItemClick(nodes)}>
              <Grid
                container
                className={classes.areabar}
                style={
                  depth === 0
                    ? {
                        backgroundColor: defaulttheme.blue.delta,
                        color: "white",
                      }
                    : { backgroupdColor: "gray" }
                }
              >
                <Grid item xs={6}>
                  {/* <Typography className={classes.areamain}>{areaName}</Typography> */}
                  <ListItemIcon>
                    <FolderIcon />
                  </ListItemIcon>
                  <span style={{ fontSize }}>
                    {nodes.isEnabled ? nodes.name : nodes.name + "  (停用)"}{" "}
                  </span>
                </Grid>
                {selectNode && nodes.locationId === selectNode.locationId
                  ? renderButtons
                  : null}
              </Grid>
            </div>
          }
        >
          {Array.isArray(nodes.childs)
            ? nodes.childs.map((node) => renderTree(node, depth + 1))
            : null}
        </TreeItem>
      </>
    );
  };

  return (
    <>
      <TreeView
        defaultCollapseIcon={
          Array.isArray(data.childs) && data.childs.length > 0 ? (
            <ExpandMoreIcon />
          ) : null
        }
        defaultExpandIcon={
          Array.isArray(data.childs) && data.childs.length > 0 ? (
            <ChevronRightIcon />
          ) : null
        }
        key={data.locationId}
        defaultExpanded={[data.locationId]}
      >
        {renderTree(data, 0)}
      </TreeView>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="addform-dialog-title"
      >
        <DialogTitle id="addform-dialog-title">新增區域</DialogTitle>
        <DialogContent>
          區域名稱:
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="名稱"
            onChange={(e) => setAreaName(e.target.value)}
            fullWidth
          />
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>停用</Grid>
              <Grid item>
                <Switch
                  checked={enable}
                  onChange={(e) => handleEditEnable(e.target.checked)}
                  name="isEnabled"
                  color="primary"
                />
              </Grid>
              <Grid item>啟用</Grid>
            </Grid>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSaveArea} color="primary">
            確定
          </Button>
          <Button variant="contained" onClick={handleClose} color="secondary">
            取消
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open_edit}
        onClose={handleClose_Edit}
        aria-labelledby="editform-dialog-title"
      >
        <DialogTitle id="editform-dialog-title">修改區域</DialogTitle>
        <DialogContent>
          區域名稱:
          <TextField
            autoFocus
            margin="dense"
            id="newname"
            label="名稱"
            onChange={(e) => setAreaName(e.target.value)}
            fullWidth
            value={areaName}
          />
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>停用</Grid>
              <Grid item>
                <Switch
                  checked={enable}
                  onChange={(e) => handleEditEnable(e.target.checked)}
                  name="isEnabled"
                  color="primary"
                />
              </Grid>
              <Grid item>啟用</Grid>
            </Grid>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleEditArea} color="primary">
            確定
          </Button>
          <Button
            variant="contained"
            onClick={handleClose_Edit}
            color="secondary"
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default TreeViewComponent;
