import axios from "axios";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/authn/v1/auth";

export const login = (username, password) => {

  // console.log(`${ROOT_URL}${BASE_URL}`)
  return axios.post(`${ROOT_URL}${BASE_URL}`, {
    method: "parkmgt",
    username,
    password,
  });
};

export const refeshToken = (token) => {
  return axios.post(`${ROOT_URL}/api/authn/v1/refreshToken`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
}