import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/parkingTicket";
const BASE_URL_V2 = "/api/v2/parkingTicket";

export const getParkingTickets = (parkingspaceId, skip = 0, amount = 10) => {
  if (parkingspaceId.trim() === "")
    return apiProvider.get(
      `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}`
    );
  else
    return apiProvider.get(
      `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}&param[parkingSpaceId]=${parkingspaceId}`
    );
};

export const getParkingTicketsByIds = (parkingspaceIds, skip = 0, amount = 10) => {

  // 將陣列轉換為逗號分隔的字符串
  const parkingSpaceIdsParam = parkingspaceIds.join(",");
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}&param[parkingSpaceId]=${parkingSpaceIdsParam}`
  );
};

export const getParkingTicketsByConditions = (start, end, plateNumber, ticketStatus, parkingSpaceIds, skip = 0, amount = 10) => {


  let url = `${ROOT_URL}${BASE_URL_V2}/byCondition?skip=${skip}&amount=${amount}`;


  // 判斷 start 跟 end 不為空值, 加到 URL
  if (start && end) {
    url += `&start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`;
  }

  // 判斷 plateNumber 不為空值, 加到 URL
  if (plateNumber) {
    url += `&plateNumber=${encodeURIComponent(plateNumber)}`;
  }

  // 判斷 ticketStatus 不為空值, 加到 URL
  if (ticketStatus) {
    url += `&status=${encodeURIComponent(ticketStatus)}`;
  }

  // 判斷 parkingSpaceIds 的長度大於 0, 循環加到 URL
  if (parkingSpaceIds && parkingSpaceIds.length > 0) {
    parkingSpaceIds.forEach(id => {
      url += `&parkingSpaceIds=${encodeURIComponent(id)}`;
    });
  }

  

  return apiProvider.get(url);
};


export const getParkingTicketById = (parkingTicketId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${parkingTicketId}`);
};

export const getParkingTicketHistoriesById = (parkingTicketId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${parkingTicketId}/histories`);
};

export const getParkingTicketSnapshotList = (parkingTicketId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${parkingTicketId}/snapshots`);
};

export const getParkingTicketEvents = (type, skip = 0, amount = 10, start = null, end = null) => {
  let qs = '';

  if (start != null) {
    qs += `&start=${start}`;
  }

  if (end != null) {
    qs += `&end=${end}`;
  }

  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}/events/${type}?skip=${skip}&amount=${amount}${qs}`
  );
}