import React, { useContext } from "react";
import { GlobalContext } from "../contexts/GlobalContext";
import Dashboard from "./component/Dashboard";

const Home = () => {
  const { t } = useContext(GlobalContext);
  return <Dashboard />;
};

export default Home;
