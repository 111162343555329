import apiProvider from "./apiProvider";
import { ROOT_URL } from "../config/api";

const BASE_URL = "/api/v1/user/authorization";



export const getAuthorizations = (skip=0, amount=10) => {
  return apiProvider.get(
    `${ROOT_URL}${BASE_URL}?skip=${skip}&amount=${amount}`
  );
};

export const getAuthorizationById = (authorizationId) => {
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${authorizationId}`);
};

export const getAuthorizationByName = (authorizationName) =>{
  return apiProvider.get(`${ROOT_URL}${BASE_URL}/${authorizationName}/scopes`);
};



export const addAuthorization = (authorizationData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .post(`${ROOT_URL}${BASE_URL}`, authorizationData)
      .then((response) => {
        console.log("新增成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("新增失败", error);
        reject(error);
      });
  });
};

export const getAuthorizationNames = ()=>{
  return  apiProvider.get(
    
    `${ROOT_URL}${BASE_URL}/names`
  );
}


export const updateAuthorization = (authorizationData) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .put(
        `${ROOT_URL}${BASE_URL}/${authorizationData.authorizationId}`,
        authorizationData
      )
      .then((response) => {
        console.log("更新成功", response.data);
        resolve(response.data); // 返回成功结果
      })
      .catch((error) => {
        console.error("更新失败", error);
        reject(error);
      });
  });
};

export const deleteParkingLot = (authorizationId) => {
  return new Promise((resolve, reject) => {
    apiProvider
      .delete(`${ROOT_URL}${BASE_URL}/${authorizationId}`)
      .then((response) => {
        console.log("删除成功", response.data);
        resolve(response.data);
      })
      .catch((error) => {
        console.error("删除失败", error);
        reject(error);
      });
  });
};
