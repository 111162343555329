import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function DeleteConfirmationDialog({ open, onClose, onDelete, item }) {
  const handleDelete = () => {
    onDelete(item);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>刪除確認</DialogTitle>
      <DialogContent>
        <DialogContentText>確定要刪除嗎? 此操作不可復原</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          取消
        </Button>
        <Button onClick={handleDelete} color="primary">
          確認刪除
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmationDialog;
