import React from "react";
import { useContext } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@material-ui/core';
import { useEffect, createContext, useState, Fragment } from "react";
import * as dayjs from 'dayjs'

import { GlobalContext } from "../../contexts/GlobalContext";
import SnapshotCard from "./SnapshotCard";

import { getParkingSpaceSnapshotBySpaceId } from "../../utils/ParkingSpaceApi";

const Page = ({ spaceData, lotData, open, setOpen }) => {
    const { showSnackbar, setIsLoading } = useContext(GlobalContext);
    const [sectionName, setSectionName] = React.useState("");
    const [spaceName, setSpaceName] = React.useState("");
    const [deviceId, setDeviceId] = React.useState("");
    const [snapshotData, setSnapshotData] = React.useState([]);
    const [selectedData, setSelectedData] = React.useState(dayjs().format('YYYY-MM-DD'));

    useEffect(() => {
        // fetch data
        setSelectedData(dayjs().format('YYYY-MM-DD'));
        if (
            spaceData === null || 
            spaceData.spaceData === undefined || 
            spaceData.type === undefined ||
            lotData === null || 
            lotData === '') {
            return;
        }

        setSectionName(lotData.name);
        setSpaceName(spaceData.spaceData.parkingSpaceName || "");
        setDeviceId(spaceData.spaceData.deviceId || "");

        // console.log(spaceData, lotData);
        fetchData(dayjs().format('YYYY-MM-DD'));
        // spaceData.parkingSpaceId



    }, [spaceData, lotData]);

    const fetchData = async (targetDate) => {
        setSnapshotData([]);
        setIsLoading(true);
        try {
            const start = dayjs(targetDate).toISOString();
            const end = dayjs(targetDate).add(1, 'day').toISOString();

            // console.log('Start:', start);
            // console.log('End:', end);

            const response = await getParkingSpaceSnapshotBySpaceId(spaceData.spaceData.parkingSpaceId, spaceData.type, start, end);
            if (response.status === 200 && response.data && response.data.data) {
                setSnapshotData(response.data.data);
                // console.log(response.data.data);
                if (response.data.data.length === 0) {
                    showSnackbar({
                        message: "選擇的日期沒有快照紀錄"
                    });
                }
            }

            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        };
    }

    const handleDataChange = (e) => {
        setSelectedData(e.currentTarget.value);
        fetchData(e.currentTarget.value);
    };

    return (
        <Dialog
            maxWidth="md"
            onClose={() => setOpen(false)}
            aria-labelledby="simple-dialog-title"
            open={open}
        >
            <DialogTitle id="simple-dialog-title">格位快照 - {sectionName} {spaceName} - {deviceId}</DialogTitle>
            <DialogContent>
                <Box sx={{ mb: "5px" }}>
                    日期: <input type="date" id="start" name="trip-start" value={selectedData} onChange={handleDataChange} />
                </Box>

                {snapshotData.map((snapshot, index) => (
                    <Fragment key={index}>
                        <SnapshotCard snapshot={snapshot} />
                    </Fragment>
                ))}
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => setOpen(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Page;