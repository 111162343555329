// signalrService.js
import * as signalR from "@microsoft/signalr";
import { token, tokenIsValid } from "./tokenHandler";
import { ROOT_URL } from "../config/api";

let signalRConnection = null;
let HUB_URL = "/api/v1/hub/wai";
let isConnectionStarted = false;

const disconnectSignalRConnection = () => {
  if (signalRConnection && isConnectionStarted) {
    signalRConnection.stop().then(() => {
      console.log("SignalR Disconnected!");
      isConnectionStarted = false;
    }).catch((error) => {
      console.error("SignalR Disconnection Error: ", error);
    });
  }
};


// 檢查 token 是否有效，如果有效，則建立連線
const createSignalRConnection = () =>{
    if (tokenIsValid() && !isConnectionStarted) {
        console.log("start connect signalR");
        signalRConnection = new signalR.HubConnectionBuilder()
          .withUrl(`${ROOT_URL}${HUB_URL}`, { accessTokenFactory: () => token })
          .configureLogging(signalR.LogLevel.Information)
          .build();

          signalRConnection.onclose(function (e) {
            isConnectionStarted = false;
            startSignalRConnection();
        });
           
          const startSignalRConnection = () => {
            signalRConnection.start().then(() => {
                console.log("SignalR Connected!");
                if(isConnectionStarted == false)
                {
                  isConnectionStarted = true;
                }
            }).catch((error) => {
                console.error("SignalR Connection Error: ", error);
                // 連接失敗後重新嘗試連接
                isConnectionStarted = false;
                setTimeout(function () { startSignalRConnection(); }, 5000);
            });
        };

        startSignalRConnection();
      } else {
        console.log("Not starting SignalR connection due to invalid token.");
        // 如果需要在 token 無效時執行其他邏輯，可以在這裡添加相應的程式碼
        setTimeout(function () { createSignalRConnection(); }, 5000);
      }
};




export { signalRConnection, createSignalRConnection , disconnectSignalRConnection};