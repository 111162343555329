import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, ImageList, ImageListItem, ImageListItemBar, DialogActions, Button, Select, MenuItem } from "@material-ui/core";
import ReactImageMagnify from "react-image-magnify";
import { GlobalContext } from "../../contexts/GlobalContext";
import { ROOT_URL } from "../../config/api.js";

import {
    getParkingTickets,
    getParkingTicketsByIds,
    getParkingTicketHistoriesById,
    getParkingTicketSnapshotList,
} from "../../utils/ParkingTicketApi";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
    },
    paper: {
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    imageList: {
        flexWrap: "nowrap",
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
        width: "100%",
        height: "100%",
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));

const ISO8601ToDateTime = (isoDateString) => {
    const date = new Date(isoDateString);

    // 確保Date物件有效，並格式化日期和時間
    if (!isNaN(date.getTime())) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    } else {
        return "Invalid Date";
    }
}

const ReanderPreviewImage = ({ parkingTicketIds, open, setOpen }) => {
    const classes = useStyles();
    const [imageItems, setImageItems] = useState([]);
    const [selectedParkingTicketId, setSelectedParkingTicketId] = useState("");
    const { t } = useContext(GlobalContext);
    const handleClosePreview = () => {
        setOpen(false);
    }

    const handleSelectionChanged = (e) => {
        setSelectedParkingTicketId(e);
    };

    useEffect(() => {
        if (parkingTicketIds && parkingTicketIds.length > 0) {
            setSelectedParkingTicketId(parkingTicketIds[0]);
        }
    }, [parkingTicketIds]);

    useEffect(() => {
        // get patkingTicket data
        if (selectedParkingTicketId !== "" && selectedParkingTicketId !== null) {
            getParkingTicketSnapshotList(selectedParkingTicketId).then((resp) => {
                let items = [];
                resp.data.data.map((image, index) => {
                    items.push({
                        img: `${ROOT_URL}/api/v1/parkingTicket/${selectedParkingTicketId}/snapshot/${image.filename}`,
                        author: "author",
                        title: index,
                        time: image.time,
                        reason: image.reason,
                    });
                });

                setImageItems(items);
            });
        }
        // generate imageItems
        // set imageItems
    }, [selectedParkingTicketId]);


    return (
        <Dialog
            onClose={handleClosePreview}
            aria-labelledby="simple-dialog-title"
            open={open}
            PaperProps={{
                style: {
                    width: "50%",
                    height: "50%",
                    position: "absolute",
                    top: "25%",
                    left: "25%",
                },
            }}
        >
            <DialogTitle id="simple-dialog-title">Images</DialogTitle>
            <DialogContent>
                <Select
                    defaultValue=""
                    d="grouped-select"
                    onChange={(e) => handleSelectionChanged(e.target.value)}
                    style={{ width: "200px", marginLeft: "10px" }}
                    value={selectedParkingTicketId}
                >
                    {parkingTicketIds
                        ? parkingTicketIds.map((d, index) => (
                            <MenuItem key={d} value={d}>
                                {`停車單 Id: ${d}`}
                            </MenuItem>
                        ))
                        : null}
                </Select>
                <ImageList className={classes.imageList} cols={1}>
                    {imageItems
                        ? imageItems.map((item) => (
                            <ImageListItem key={item.img} style={{ height: "auto" }}>
                                {
                                    /* <img src={item.img} alt={item.title} /> */
                                    <ReactImageMagnify
                                        {...{
                                            smallImage: {
                                                alt: item.title,
                                                src: item.img,
                                                isFluidWidth: true,
                                                hoverOffSetX: 0,
                                                hoverOffSetY: 0,
                                            },
                                            largeImage: {
                                                src: item.img,
                                                width: 1920,
                                                height: 1080,
                                            },
                                            enlargedImagePosition: "over",
                                            isActivatedOnTouch: false,
                                            isHintEnabled: true,
                                        }}
                                    />
                                }
                                {/* <ImageComponent image={item.img}/> */}
                                <ImageListItemBar
                                    title={ISO8601ToDateTime(item.time)}
                                    position="top"
                                    subtitle={item.reason}
                                    className={classes.titleBar}
                                    style={{ pointerEvents: "none" }}
                                />
                            </ImageListItem>
                        ))
                        : null}
                </ImageList>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleClosePreview}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default ReanderPreviewImage;